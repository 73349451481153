@import '../../../general/scss/settings/variables.scss';

.check {
    .label {
        display: none;
    }

    .label-visible {
        display: block;
        float: right;
        margin-left: .5rem;
        padding-top: .25rem;
    }

    input.styled-checkbox,
    span.styled-checkbox input {
        display: none;

        &:checked~.box {
            background-color: lighten($unipol-light-blue, 35%);

            &:after {
                top: 0;
            }
        }
    }

    .box {
        width: 32px;
        height: 32px;
        display: inline-block;
        transition: all 1.1s cubic-bezier(.19, 1, .22, 1);
        border: 1px solid $unipol-light-blue;
        background-color: $unipol-off-white;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &:after {
            width: 66%;
            height: 33%;
            content: '';
            position: absolute;
            border-left: 4px solid;
            border-bottom: 4px solid;
            border-color: $unipol-light-blue;
            transform: rotate(-45deg) translate3d(0, 0, 0);
            transform-origin: center center;
            transition: all 1.1s cubic-bezier(.19, 1, .22, 1);
            left: 0;
            right: 0;
            top: 200%;
            bottom: 5%;
            margin: auto;
        }
    }
}



/*.table .checkbox, .table .radio {
	margin: 0;
}

.checkbox input {
	display: none !important;
	visibility: hidden;
}

input[type="checkbox"] {
	display: none !important;
	visibility: hidden;
}

.checkbox input + label {
	user-select: none;
	background: url(/assets/icons/24/tick-1be3cb7dd1f930ff54000a74e0d7d0df57f1819492926a1a46a9c98dbdfb86d9.png) no-repeat 0 0 transparent;
	cursor: pointer;
	display: inline-block;
	line-height: 24px;
	padding-left: 32px;
	vertical-align: middle;

	&:empty {
		height: 24px;
		padding-left: 0;
		width: 24px;
	}
}

input[type="checkbox"] {

	+ label {
		user-select: none;
		background: url(/assets/icons/24/tick-1be3cb7dd1f930ff54000a74e0d7d0df57f1819492926a1a46a9c98dbdfb86d9.png) no-repeat 0 0 transparent;
		cursor: pointer;
		display: inline-block;
		line-height: 24px;
		padding-left: 32px;
		vertical-align: middle;

		&:empty {
			height: 24px;
			padding-left: 0;
			width: 24px;
		}
	}

	&:checked {
		+ label {
			background-image: url(/assets/icons/24/tick-active-c9dc6a6db24a1774092607fd18d2a000b127923e93e84d44caa07716640fe3e2.png);
		}
	}
}*/